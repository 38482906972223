import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

function App() {
  return (
    <div className="app-container">
      {/* Title Banner */}
      <div className="title-banner">
        <h1>Let's play Secret Santa</h1>
      </div>

      {/* Main Content */}
      <div className="main-container">
        <CreateGroup />
        <div className="divider" />
        <JoinGroup />
      </div>
    </div>
  );
}

function CreateGroup() {
  const [groupName, setGroupName] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [eventDate, setEventDate] = useState('');
  const [isParticipating, setIsParticipating] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setIsEmailValid(emailRegex.test(value));
  };

  const handleSubmit = async () => {
    if (!isEmailValid) {
      alert("Please enter a valid email address.");
      return;
    }

    const data = {
      groupName,
      email,
      eventDate,
      isParticipating,
      firstName: isParticipating ? firstName : undefined,
      lastName: isParticipating ? lastName : undefined,
    };

    try {
      const response = await fetch('https://api.905418420831.realhandsonlabs.net/dummy', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        mode: 'no-cors',
      });
      const result = await response.json();
      console.log(result); // Handle response
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="section">
      <h2>Create Group</h2>
      <p>Create a group and share it with your friends and family.</p>
      <input
        type="text"
        placeholder="Group Name"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
      />
      <div className="input-with-icon">
        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={handleEmailChange}
        />
        <span className={`validation-icon ${isEmailValid ? 'valid' : 'invalid'}`}>
          {email && (isEmailValid ? '✔' : '✘')}
        </span>
      </div>
      <div className="datepicker-wrapper">
        <DatePicker
          selected={eventDate}
          onChange={(date) => setEventDate(date)}
          placeholderText="Event Date"
          minDate={new Date()} // Prevent past dates
          dateFormat="MMMM d, yyyy"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          className="datepicker"
        />
      </div>
      <div className="participation">
        <label>
          <input
            type="checkbox"
            checked={isParticipating}
            onChange={() => setIsParticipating(!isParticipating)}
          />
          I am participating in the group
        </label>
      </div>
      {isParticipating && (
        <div className="name-fields">
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      )}
      <button onClick={handleSubmit} disabled={!isEmailValid}>
        Create Group
      </button>
    </div>
  );
}

function JoinGroup() {
  const [groupId, setGroupId] = useState('');
  const [passcode, setPasscode] = useState('');

  const handleSubmit = async () => {
    const data = { groupId, passcode };
    try {
      const response = await fetch('https://example.com/api/join-group', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      console.log(result); // Handle response
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="section">
      <h2>Join a Group</h2>
      <p>Already in a group? Join an existing group here.</p>
      <input
        type="text"
        placeholder="Group ID"
        value={groupId}
        onChange={(e) => setGroupId(e.target.value)}
      />
      <input
        type="password"
        placeholder="Passcode"
        value={passcode}
        onChange={(e) => setPasscode(e.target.value)}
      />
      <button onClick={handleSubmit}>Join Group</button>
    </div>
  );
}



export default App;
